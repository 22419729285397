import { Routes, Route } from "react-router-dom";
import { siteMap } from "./SiteMap";

import Home from "../Pages/Home/Home";
import Gallery from "../Pages/Gallery/Gallery";
import Team from "../Pages/Team/Team";
import Contact from "../Pages/Contact/Contact";
// import ScrollToTop from "./ScrollToTop";

import PrivacyPolicy from "../Pages/PrivacyPolicy/privacyPolicy";
import Terms from "../Pages/TermsConditions/Terms";
import Refund from "../Pages/RefundCancellation/Refund";
import Shipping from "../Pages/ShippingDelivery/Shipping";
const PageRoutes = () => {
  return (
   
      <Routes>
        <Route path={siteMap.HomePage.path} element={<Home />} />
        <Route path={siteMap.GalleryPage.path} element={<Gallery />} />
        <Route path={siteMap.TeamPage.path} element={<Team />} />
        {/* <Route path={siteMap.ContactPage.path} element={<Contact />} />
        <Route path={siteMap.PrivacyPage.path} element={<PrivacyPolicy />} />
        <Route path={siteMap.TermsPage.path} element={<Terms />} />
        <Route path={siteMap.RefundPage.path} element={<Refund />} />
        <Route path={siteMap.ShippingPage.path} element={<Shipping />} /> */}
      </Routes>
  );
};

export default PageRoutes;
