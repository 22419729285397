export const siteMap = {
  HomePage: {
    title: "Home",
    path: "/",
    description: "SwitchBoard's home page",
  },
  GalleryPage: {
    title: "Gallery",
    path: "/gallery",
    description: "SwitchBoard's gallery page",
  },
  TeamPage: {
    title: "Team",
    path: "/team",
    description: "SwitchBoard's team page",
  },
  ContactPage: {
    title: "Contact",
    path: "/contact",
    description: "SwitchBoard's contact page",
  },
  PrivacyPage: {
    title: "Privacy",
    path: "/privacy-policy",
    description: "Privacy-policy",
  },
  TermsPage: {
    title: "Terms",
    path: "/terms-and-conditions",
    description: "terms-and-conditions",
  },
  RefundPage: {
    title: "Refund",
    path: "/refund-and-cancellation",
    description: "refund-and-cancellation",
  },
  ShippingPage: {
    title: "Shipping",
    path: "/shipping-and-delivery",
    description: "shipping-and-delivery",
  }
};
