import Cultural from "../Assets/Images/Events/wedding/CulturalTradition.png"
import Decorations from "../Assets/Images/Events/wedding/Decorations.png"
import Food from "../Assets/Images/Events/wedding/Food.png"
import event from "../Assets/Images/Events/event/events-1.png"
import language from "../Assets/Images/Events/event/language.png"
import playlist from "../Assets/Images/Events/event/playlist.png"
import genre from "../Assets/Images/Events/event/genre.png"

export const eventsContent = (GetThemeStatus) => {
    return !GetThemeStatus ?
        [
            {
                image: language,
                heading: "Multilingual",
                description: "Our application offers a unique multilingual feature, allowing users to enjoy their favorite stories in their preferred South Indian regional languages. With support for Tamil, Telugu, Malayalam, and Kannada, users can seamlessly switch between different translations of the same story, enhancing their listening experience. This feature caters specifically to a South Indian audience. Our advanced translation technology maintains the quality and nuance of the original narration, providing an authentic and enjoyable auditory experience. Now, immerse yourself in stories available in Tamil, Telugu, Malayalam, and Kannada, and experience the richness of diverse languages through our platform."
            },
            {
                image: playlist,
                heading: "Customized Playlists",
                description: "Radio Room offers a customized playlist feature, allowing you to curate your own collection of stories to suit your mood and interests. With this feature, you can create personalized playlists, selecting your favorite stories from a wide range of genres and languages. Whether you're in the mood for a thrilling mystery, a heartwarming romance, or an inspiring biography, our application lets you tailor your listening experience to match your preferences. Additionally, you can easily manage and update your playlists, ensuring you always have the perfect story ready for any occasion. Enhance your storytelling experience with our customized playlist feature."
            },
            {
                image: genre,
                heading: "Multiple Genres",
                description: "Explore a multitude of genres and discover a world of stories with our multi-genre feature. Radio Room caters to a diverse range of tastes and preferences, offering something for everyone. Dive into classic fairy tales that transport you to magical worlds, or get lost in gripping mysteries that keep you on the edge of your seat. Whether you enjoy thrilling adventures, heartwarming romances, thought-provoking dramas, or inspiring biographies, our app has it all. With such a wide variety of genres available, you can easily find stories that match your interests and mood. Unleash your imagination and embark on endless storytelling journeys with our multi-genre feature."
            }
        ] : [
            {
                image: Cultural,
                heading: "Cultural Traditions",
                description: "we specialize in cultural and traditional weddings that helps couples plan and execute weddings that are steeped in cultural and traditional customs and practices. We maintain the expression of the couple's heritage, identity, and beliefs and typically involve unique rituals, customs, dress codes, and food traditions that are specific to the couple's culture and religion."
            },
            {
                image: Decorations,
                heading: "Decorations",
                description: "we offer a wide range of decoration options, including flower arrangements, lighting, backdrops, linens, and table settings, and may also provide additional services such as setup and cleanup. Our team of experienced designers and decorators who work together to create the perfect ambiance for the wedding, and may be equipped to handle weddings of different sizes and styles, including outdoor and indoor weddings."
            },
            {
                image: Food,
                heading: "Food",
                description: "we offer a range of food services for weddings, including catering, bar services, and other related food-related services. we understand their food preferences, dietary restrictions, and cultural and traditional requirements and provide a customized menu that reflects these requirements. we also provide additional services such as setup, service, and cleanup, and have a team of professional chefs and servers who ensure that the food is of high quality and is served in a timely and efficient manner."
            }
        ]
}